/* At the top of your App.css */
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;600&display=swap');

/* App.css */
.text {
  color: rgba(0, 0, 0, 0.2); /* Light grey with low opacity */
  font-size: 20px; /* Adjust the size as needed */
  /* cursor: pointer; Change cursor to pointer for clickable texts */
}

@keyframes highlightAnimationYellow {
  from {
    color: rgba(0, 0, 0, 0.2);
  }
  to {
    color: black;
    background-color: yellow;
  }
}

@keyframes highlightAnimationAqua {
  from {
    color: rgba(255, 255, 255, 1);
  }
  to {
    color: rgb(0, 0, 0); 
    background-color: aqua;
  }
}

.text-container {
  width: 100%; /* Default for mobile */
  margin: 0 auto; /* Center the container */
  max-width: 700px; /* Adjust this value based on your content's optimal readability */
}

/* Media query for screens wider than 768px */
@media (min-width: 1000px) {
  .text-container {
    width: 100%;
  }
}

.legal-button {
  position: fixed;
  bottom: 20px;
  left: 20px;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: white;
  border: none;
  font-size: 24px;
  cursor: pointer;
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
}

.legal-button.dark-mode {
  background-color: #333;
  color: aqua;
}

/* ... (existing styles) */

.modal-text-container {
  max-height: 400px;
  overflow-y: auto;
  padding: 20px;
}

.modal-text-container h2 {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 10px;
}

.modal-text-container h3 {
  font-size: 20px;
  font-weight: bold;
  margin-top: 20px;
  margin-bottom: 10px;
}

.modal-text-container p {
  margin-bottom: 10px;
}

.modal-text-container ul,
.modal-text-container ol {
  margin-left: 20px;
  margin-bottom: 10px;
}

.modal-text-container li {
  margin-bottom: 5px;
}

.modal-text-container strong {
  font-weight: bold;
}

.modal-text-container em {
  font-style: italic;
}

/* ... (existing styles) */

input[type="text"] {
  font-size: 60px; /* Increase font size */
  background-color: transparent; /* Make background transparent */
  border: none; /* Remove border */
  outline: none; /* Remove outline to hide the box completely when not focused */
  color: #000000; /* Adjust text color as needed */
  font-family: 'Poppins', sans-serif; /* Example: Using Google Fonts */
  text-align: right; /* Center the text inside the input box */
  font-style: bold;
}

.app-container {
  display: flex;
  height: 100vh;
  background-color: rgb(255, 255, 251);
}

.left-half, .right-half {
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: flex-end; /* Align items to the right for the left half */
}

/* Ensure alignment and adjust right margin */
.left-half {
  justify-content: center;
  padding-right: 10px; /* Decreased right padding/margin for closer alignment to the divider */
  align-items: flex-end; /* Aligns items to the right */
}

.right-half {
  overflow-y: auto; /* Adds scroll to the right half if content overflows */
}

.word-stack div {
  margin-bottom: 2px; /* Decreased from 10px for tighter spacing */
  color: lightgray;
  text-align: right;
  font-size: 30px; /* Increase font size */
  font-style: italic;
  font-weight: 500;
  cursor: pointer;
}

.text-container a:hover, .text a:hover {
  text-decoration: underline; /* Adds underline on hover for visual feedback, optional */
}

.text-container {
  width: 100%; /* Fill the right half */
  overflow-y: auto; /* In case of overflow */
  padding: 20px; /* Padding around text */
  color: rgba(0, 0, 0, 0.2); /* Light grey text */
}

.text-container a, .text a {
  text-decoration: none; /* Removes the underline */
  color: inherit; /* Keeps the link color consistent with surrounding text */
  cursor: pointer; /* Changes cursor to indicate it's clickable */
}

/* Additional style to indicate hover state, optional */
.text-container a:hover, .text a:hover {
  text-decoration: underline; /* Adds underline on hover for visual feedback, optional */
}

.peace-button {
  position: fixed;
  top: 20px; /* Adjusted from bottom to top */
  left: 20px; /* Adjusted from right to left */
  outline: 5px;
  outline-color: salmon;
  background-color: rgb(255, 255, 251);
  color: white;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  font-size: 24px;
  cursor: pointer;
  z-index: 100;
}

.transcripts-button {
  position: fixed;
  top: 100px;
  left: 20px; 
  outline: 5px;
  outline-color: salmon;
  background-color: rgb(255, 255, 251);
  color: white;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  font-size: 40px;
  cursor: pointer;
  z-index: 100;
}

.photos-button {
  position: fixed;
  top: 180px;
  left: 20px; 
  outline: 5px;
  outline-color: salmon;
  background-color: rgb(255, 255, 251);
  color: white;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  font-size: 40px;
  cursor: pointer;
  z-index: 100;
}


.modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 101;
}

.dark-mode .modal {
  background-color: rgba(39, 39, 39, 0.5); /* Darker shade for dark mode */
}

.modal-content {
  background: white;
  padding: 20px;
  border-radius: 10px;
  max-width: 450px;
  width: 90%;
}

.close {
  float: right;
  font-size: 28px;
  cursor: pointer;
  margin-top: -20px;
  margin-right: -10px;
}

.modal-image {
  width: 100%; /* Adjust based on your preference */
  border-radius: 10px; /* Square with rounded edges */
  margin-bottom: 0px;
}

.modal-text {
  margin: 1px;
  color: #696969;
}

.highlight {
  background-color: yellow;
}

.modal-title {
  font-size: 24px; /* Example size */
  margin-bottom: 0px; /* Reduced bottom margin */
  margin-top: 5px; /* Reduced bottom margin */
  font-weight: 600; /* Assuming Poppins font-weight for a bold look */
  color: #333;
  font-family: 'Poppins', sans-serif;
}

.text a {
  text-decoration: none; /* Removes the underline */
  color: inherit; /* Keeps the link color consistent with surrounding text */
  cursor: pointer; /* Changes cursor to indicate it's clickable */
}

.underline-link {
  text-decoration: underline; /* Adds underline to the link */
  color: inherit; /* Keeps the link color consistent with surrounding text */
  cursor: pointer; /* Changes cursor to indicate it's clickable */
}

/* Dark mode styles */
.dark-mode {
  background-color: #121212; /* Dark background */
  color: #ffffff; /* Bright text */
}

.dark-mode .text, .dark-mode .modal-text underline-link {
  color: #ffffff; /* Bright text for dark mode */
}

.dark-mode .large-input {
  color: #ffffff; /* Bright text for dark mode */
}

.dark-mode .highlight {
  background-color: #007bff; /* Neon blue highlight */
}

/* Adjust this to ensure links are visible in dark mode */
.dark-mode .text a, .dark-mode .underline-link {
  color: #9ab; /* Brighter link color for dark mode */
}

.page-title {
  position: fixed;
  bottom: 5px; /* Align with the peace-button */
  left: 5px; /* Slightly offset from the very edge */
  font-size: 60px; /* Match the input box size */
  font-family: 'Poppins', sans-serif; /* Ensure consistency */
  color: #000000; /* Default color, adjust if necessary */
  z-index: 100; /* Ensure it's visible above other content */
  font-weight: 700; /* Assuming Poppins font-weight for a bold look */
}

.peace-button {
  position: fixed;
  top: 20px;
  left: calc(20px); /* Adjust this value based on the actual width of the .page-title */
  /* Remaining styles unchanged */
  font-size: 40px;
}

/* Add dark mode specific styles for the title if needed */
.dark-mode .page-title {
  color: #ffffff; /* Ensure visibility in dark mode */
}




