.writing-showcase-wrapper {
  padding: 40px 0;
  position: relative;
}

.back-button {
  position: absolute;
  top: 20px;
  left: 20px;
  background: none;
  border: none;
  cursor: pointer;
  font-size: 16px;
  color: #007bff;
  display: flex;
  align-items: center;
}

.back-button::before {
  content: '←';
  margin-right: 5px;
  font-size: 20px;
}

.container {
  max-width: 800px;
  margin: 0 auto;
  padding: 0 20px;
}

.heading {
  font-size: 36px;
  text-align: center;
  margin-bottom: 20px;
}

.intro {
  text-align: center;
  font-size: 18px;
  margin-bottom: 40px;
}

.writing-list {
  display: grid;
  gap: 20px;
}

.writing-card {
  background-color: #f8f9fa;
  border-radius: 8px;
  padding: 20px;
  cursor: pointer;
  transition: transform 0.2s ease-in-out;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.writing-card:hover {
  transform: translateY(-5px);
}

.writing-card-title {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 10px;
}

.writing-card-content {
  font-size: 16px;
}

.writing-modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  background-color: white;
  padding: 40px;
  border-radius: 8px;
  max-width: 800px;
  max-height: 80vh;
  overflow-y: auto;
  position: relative;
}

.close-button {
  position: absolute;
  top: 20px;
  right: 20px;
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
}